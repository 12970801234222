import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core';
import { CatlougeTypeConstants } from 'src/app/core/constants';
import { AssetsService } from 'src/app/core/services/assets.service';
import { customDataForAssets, customDataForBanner, customDataForDummyData, customOptionsForBigSlider, customOptionsForFiveMiniSlider, customOptionsForFourMiniSlider, customOptionsForSevenMiniSlider, customOptionsForSixMiniSlider, customOptionsForThreeMiniSlider } from 'src/app/shared/owl-slider-config';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { CommonService } from "src/app/core/services/common.service";
import { environment } from 'src/environments/environment';
import { PageTitleService } from 'src/app/services/title/page-title.service';

declare var $;
@Component({
  selector: 'app-dynamic-tab',
  templateUrl: './dynamic-tab.component.html',
  styleUrls: ['./dynamic-tab.component.css']
})
export class DynamicTabComponent implements OnInit {
  progressInc: number = 0;
  progrssBarValue: number = 0;
  userInfo: any;
  Location: any;
  owlBannerData: any = customDataForBanner; // = {banner_result: {banner: []}}; // 
  owlSliderData: any = customDataForDummyData;
  customOptionsForBigSlider = customOptionsForBigSlider
  customOptionsForFourMiniSlider = customOptionsForFourMiniSlider;
  customOptionsForFiveMiniSlider = customOptionsForFiveMiniSlider;
  customOptionsForSevenMiniSlider = customOptionsForSevenMiniSlider;
  customOptionsForSixMiniSlider = customOptionsForSixMiniSlider;
  customOptionsForThreeMiniSlider = customOptionsForThreeMiniSlider;
  private currentMenuSubject = new BehaviorSubject(false);
  public activeMenu = this.currentMenuSubject.asObservable();
  getCurrentSlug: string;

  homePageAssets: any;
  page: number = 1;
  tabDataParams: any;
  limit: number = 15;
  playListId: string;
  playListService: any;
  playListAvailable: boolean;
  playlist: any;
  routeName: any;
  // router: any;
  tempAsset_id: any;
  continueWatchingDataParams: any;
  loadMore: boolean;
  showContinueWatching: string;
  showAddBanner: boolean = false;
  selectedProfileName: string;
  selectedProfileImage: string;
  profileBasePath: string;
  showWelcomeCard: boolean;
  isAuthenticated: string;
  isBannerDataFetched: boolean;
  isTabDataFetched: boolean;

  constructor(
    public route: ActivatedRoute,
    public userService: UserService,
    public assetsService: AssetsService,
    public commonService: CommonService,
    public router: Router,
    public pageTitleService: PageTitleService,
  ) {
    // this.customOptionsForSevenMiniSlider = customOptionsForSevenMiniSlider;
    // this.customOptionsForSevenMiniSlider['slides'] = 7;
  }

  ngOnInit(): void {
    // this.userService.updateSelectedMenu('home');
    $(".dark").addClass("homeAndDetailPage");
    this.showContinueWatching = localStorage.getItem('continuewatching');
    this.liniarProgressBar();
    this.route.params.subscribe(param => {
    });
    let currentUrl = this.route.snapshot.url.join('/');
    let firstTab = JSON.parse(localStorage.getItem('tabs'))[0]?.slug;
    localStorage.setItem('slug', currentUrl || firstTab);
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.getCurrentSlug = this.userService.getCurrentSlugName;
    const pageTitle = JSON.parse(localStorage.getItem('tabs')).find(tab => tab.slug === this.getCurrentSlug).name;
   // this.pageTitleService.setTitle(pageTitle);


    let bannerDataParams = {
      "slug": this.getCurrentSlug,
    };

    let continueWatchingtabDataParams = {
      "slug": this.getCurrentSlug,
      "page": 1,
      "limit": this.limit
    };

    this.tabDataParams = {
      "slug": this.getCurrentSlug,
      "page": 1,
      "size": this.limit
    };
    this.continueWatchingDataParams = this.userService.convertoJSONToUrlEncoded(continueWatchingtabDataParams);
    let allTabsDataParams = this.userService.convertoJSONToUrlEncoded(this.tabDataParams);
    let bannerPrams = this.userService.convertoJSONToUrlEncoded(bannerDataParams);
    setTimeout(() => {
      this.getBannerData(bannerPrams);
      if (this.isAuthenticated) {
        this.continueWatchingData(this.continueWatchingDataParams);
      }
      this.getHomePageAssets(allTabsDataParams);
    }, 50);

    this.isAuthenticated = localStorage.getItem('isAuthenticated');
    this.profileBasePath = environment.api_url.replace('api/v1/', '');
    this.selectedProfileName = localStorage.getItem('profile_UserName');
    this.selectedProfileImage = localStorage.getItem('profileImageUrl');

    if(localStorage.getItem('profileSelected')) {
      localStorage.removeItem('profileSelected');
      this.showWelcomeCard = true;
      setTimeout(() => {
        this.showWelcomeCard = false;
      }, 3500);
    }

  }

  ngAfterViewInit() {
    const queryParams = { ...this.route.snapshot.queryParams };
    if (!this.userInfo && queryParams && queryParams.redirect_to_detail && queryParams.name && queryParams.code && queryParams.type) {
      document.getElementById('login-button').click();
    }
  }

  ngOnDestroy() {
    this.owlBannerData.banner_result.banner = [];
    $(".dark").removeClass("homeAndDetailPage");
  }



  getBannerData(bannerPrams) {
    this.owlBannerData.banner_result.banner = [];
    this.assetsService.getBannerData(bannerPrams).subscribe(data => {
      this.isBannerDataFetched = true;
      this.owlBannerData.banner_result.banner = data.result;
      setTimeout(() => {
        document.getElementById("nexg_ngb-progressbar").style.display = 'none';
        document.getElementById('maincontentarea').classList.remove("_beforeLoad");
      }, 500);
    });
  }


  continueWatchingData(continueWatchingDataParams) {
    // this.owlBannerData.banner_result.banner = "";
    this.assetsService.getContinueWatchingData(continueWatchingDataParams).subscribe(data => {
      this.owlBannerData['result'] = data.result;
      this.homePageAssets = data;
      setTimeout(() => {
        document.getElementById("nexg_ngb-progressbar").style.display = 'none';
        document.getElementById('maincontentarea').classList.remove("_beforeLoad");
      }, 2000);
    });
  }


  getHomePageAssets(allTabsDataParams) {
    // this.owlBannerData.banner_result.banner = "";
    this.assetsService.getHomePageTabData(allTabsDataParams).subscribe(data => {
      this.isTabDataFetched = true;
      this.owlSliderData = data;
      setTimeout(() => {
        this.showAddBanner = true;
      }, 3000);
      // setTimeout(() => {
      //   document.getElementById("nexg_ngb-progressbar").style.display = 'none';
      //   document.getElementById('maincontentarea').classList.remove("_beforeLoad");
      // }, 3000);
    });
  }

  onScroll() {
    let maxPage = Math.ceil(this.owlSliderData.total_count / this.limit);
    if (maxPage <= this.page) {
      this.loadMore = false;
      return;
    }
    this.loadMore = true;
    this.page++;
    this.tabDataParams['page'] = this.page;
    let dynamicTabsDataParams = this.userService.convertoJSONToUrlEncoded(this.tabDataParams);
    setTimeout(() => {
      this.assetsService.getHomePageTabData(dynamicTabsDataParams).subscribe(data => {

        let rs = data.result;
        let preData = this.owlSliderData.result;
        this.owlSliderData.result = [...preData, ...rs];
        this.loadMore = true;
      });
    }, 100);

  }

  deleteContinueWatching(item) {

    let categoryIndex = this.owlBannerData.result.findIndex((ele) => {
      return ele.category_code == 'HCTG008'
    });

    let assetsDataIndex = this.owlBannerData.result[categoryIndex].assets.findIndex((ele) => {
      return ele.asset_id == item.asset_id
    });

    let recentWatchedDelete = {
      asset_id: item.asset_id,
      code: item.code,
    }

    this.assetsService.deleteContinueWatching(recentWatchedDelete).subscribe(data => {
      this.owlBannerData.result[categoryIndex] = {
        ...this.owlBannerData.result[categoryIndex],
        assets : [
          ...this.owlBannerData.result[categoryIndex].assets.slice(0, assetsDataIndex),
          ...this.owlBannerData.result[categoryIndex].assets.slice(assetsDataIndex + 1)
      ]};

      document.getElementById('removeContinueModal').style.display = 'block';
      setTimeout(() => {
        document.getElementById('removeContinueModal').style.display = 'none';
      }, 3500);

    });

    // }

  }



  liniarProgressBar() {
    this.progressInc = 0;
    let progrssInterval = setInterval(() => {
      this.progrssBarValue = this.progressInc * 10;

      if (this.progressInc >= 10) {
        clearInterval(progrssInterval);
      }
      this.progressInc++;
    }, 500)

  }


  getWatchList() {

    this.playListId = localStorage.getItem('playListId');
    let temp = {
      playlistid: this.playListId ? this.playListId : '',
      catlogue: CatlougeTypeConstants.catlogue,
      plateform: "web",
      action: "getPlayListAsset1",
    }

    let urlEncodedPlayListAssetsParams = this.userService.convertoJSONToUrlEncoded(temp);
    this.playListService.getPlaylist(urlEncodedPlayListAssetsParams).subscribe(data => {
      if (data && data.result && Array.isArray(data.result)) {
        if (Array.isArray(data.result)) {
          this.playListAvailable = true;
          this.playlist = data.result;
          this.check_into_watchlist()
          return this.playlist;
        }
      }
    });

  }



  check_into_watchlist() {
    this.routeName = this.router.url;
    this.homePageAssets?.result.forEach((categoryData, categoryIndex) => {

      if (categoryData.assets.length > 0) {
        this.homePageAssets?.result[categoryIndex].assets.forEach((tempAsset, tempIndex) => {

          if (this.routeName == '/') {
            this.tempAsset_id = tempAsset.asset_mongo_id;
          } else if (this.routeName == '/tvshows') {
            this.tempAsset_id = tempAsset.asset_mongo_id;
          } else if (this.routeName == '/movies') {
            this.tempAsset_id = tempAsset._id;
          } else if (this.routeName == '/livetv') {
            this.tempAsset_id = tempAsset._id;
          }

          if (this.playlist.findIndex(tempObj => tempObj._id == this.tempAsset_id) != -1) {
            this.homePageAssets.result[categoryIndex].assets[tempIndex].is_watchlist_added = true;
          } else {
            this.homePageAssets.result[categoryIndex].assets[tempIndex].is_watchlist_added = false;
          }
        });
      }

      if (categoryIndex >= this.homePageAssets?.result.length - 1) {
        this.owlSliderData = this.homePageAssets;
        document.getElementById("nexg_ngb-progressbar").style.display = 'none';
        document.getElementById('maincontentarea').classList.remove("_beforeLoad");
        return true;
      }
    });
  }


}

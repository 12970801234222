
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Event } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { environment, } from '../environments/environment.prod';
import { EventTrackingService } from './services/google/event-tracking.service';
import { PageTitleService } from './services/title/page-title.service';
import { filter, map } from 'rxjs/operators';
declare let gtag: Function;
import { LocalStorageService } from './core/services/local-stroage.service'
import { UserTypeConstants, authKey } from "src/app/core/constants/userType.constant";
import * as e from 'express';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {


  public spinkit = Spinkit;
  skChasingDots = 'sk-chasing-dots'
  skCubeGrid = 'sk-cube-grid'
  skDoubleBounce = 'sk-double-bounce'
  skRotatingPlane = 'sk-rotationg-plane'
  skSpinnerPulse = 'sk-spinner-pulse'
  skThreeBounce = 'sk-three-bounce'
  skWanderingCubes = 'sk-wandering-cubes'
  skWave = 'sk-wave'
  activeComponent: string;
  hideHeaderFooter: boolean;
  delayFooter: boolean;

  constructor(
    private router: Router,
    private googleEventTracking: EventTrackingService,
    private pageTitleService: PageTitleService,
    private activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    private meta: Meta,
  ) {

    // if (window.performance.navigation.type == 1) {
    //   return;
    // }else{
    //   localStorage.removeItem('slugName');
    //   // window.onbeforeunload = () => {
    //   //   localStorage.removeItem('slugName');
    //   // }
    // }
    
    window.onbeforeunload = () => {
      // localStorage.removeItem('slugName');
    }
    // location.onPopState(() => {
    //   console.log(`pressed back in add!!!!!`);
    //   //this.router.navigateByUrl(‘/multicomponent’);
    //   //history.forward();
    //   });
    if (localStorage.getItem('debug') == 'false') {
      window.console.log = function () { };   // disable any console.log debugging statements in production mode
      window.console.error = function () { };
    } else {

    }
    // if (!localStorage.getItem('isAuthenticated')) {
    //   localStorage.setItem('sessionId', authKey.defaultAuthKey);
    // }

    // if(environment.production ) {
    // } else {
    //   // window.console.log = function () { };   // disable any console.log debugging statements in production mode
    //   window.console.error = function () { };
    // }

  }

  ngOnInit() {
    const appTitle = this.pageTitleService.getTitle();
    this.setPageTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }

          this.activeComponent = child.snapshot.data['componentName'];
          console.log(this.activeComponent);
          console.log(child);

          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        // console.log(ttl);
        // this.pageTitleService.setTitle(ttl);
        // this.setPageTitle();
      });



    this.router.events.subscribe((evt) => {

      if (evt instanceof NavigationEnd) {
        const urlPath = evt.urlAfterRedirects.split('?')[0];
        this.googleEventTracking.routeTrack(urlPath);
      }


      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      this.setPageTitle();
    });

    if (localStorage.getItem('isAuthenticated') && !localStorage.getItem('profile_id') && !window.location.href.includes("/manage-profile")) {
      this.router.navigate(["manage-profile"], {
        relativeTo: this.activatedRoute,
        queryParamsHandling: 'preserve',
      });  
      // window.location.href = '/manage-profile';
      }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let currenturl = window.location.href;
        console.log(currenturl);
        if (currenturl.includes("/manage-profile") || currenturl.includes("/search") || currenturl.includes("/app-pages")) {
          this.hideHeaderFooter = true;
          // let HeaderTag = document.getElementById('header');
          // HeaderTag.classList.add("no_click");

          // let footerTag = document.getElementById('footer');
          // footerTag.classList.add("no_click");
        } else {
          this.hideHeaderFooter = false;
          // let HeaderTag = document.getElementById('header');
          // HeaderTag.classList.remove("no_click");

          // let footerTag = document.getElementById('footer');
          // footerTag.classList.remove("no_click");
        }
      }
    });

    setTimeout(() => {
      this.delayFooter = true;
    }, 1000);
  }

  setPageTitle() {
    console.log('current url in app component: ', this.router.url)
    if (this.router.url.includes('search')) {
      this.pageTitleService.setTitle(`Explore Devotional Content on NexGTv - Spiritual Videos & More`);
      this.meta.updateTag({ name: 'description', content: `Watch a variety of devotional content including spiritual videos, prayers, bhajans, and more on NexGTv. Start your spiritual journey today`});
      this.meta.updateTag({ name: 'keywords', content: `Devotional Content, NexGTv, Spiritual Videos` });
      return;
    } else if (this.router.url.includes('viewall')) {
      this.pageTitleService.setTitle(`Explore All News Channels on NexGTv - Watch Live TV`);
      this.meta.updateTag({ name: 'description', content: `Browse all news channels on NexGTv. Stay updated with real-time news, live coverage, and breaking stories from India and around the world.`});
      this.meta.updateTag({ name: 'keywords', content: `News Channels,NexGTv, Live TV, Real-Time News, Breaking Stories, Live Coverage` });
    }

    const currentPage = this.router.url.split('/')[1];
    console.log('currentPage in app comp: ', currentPage)
    switch(currentPage) {
      case 'home':
        this.pageTitleService.setTitle(`nexGTv - Live TV, Movies, and On-Demand Entertainment`);
        this.meta.updateTag({ name: 'description', content: `Discover nexGTv, your go-to platform for live TV, movies, and on-demand shows. Stream 100+ channels, enjoy a variety of genres, and stay entertained anywhere.`});
        this.meta.updateTag({ name: 'keywords', content: `nexGTv, Live TV, On-Demand Entertainment` });
      break;

      case 'live-tv':
        this.pageTitleService.setTitle(`nexGTv Live TV - Stream 100+ Channels Online`);
        this.meta.updateTag({ name: 'description', content: `Watch live TV on nexGTv with over 100+ channels across genres like news, sports, entertainment, and more. Enjoy seamless streaming anytime, anywhere.`});
        this.meta.updateTag({ name: 'keywords', content: `nexGTv Live TV, Stream 100+ Channels, Online TV Streaming, News Channels` });
      break;

      case 'news-24x7':
        this.pageTitleService.setTitle(`nexGTv News 24x7 - Live News Streaming Online`);
        this.meta.updateTag({ name: 'description', content: `Stay updated with 24x7 live news on nexGTv. Stream breaking news, current affairs, and top stories from trusted channels anytime, anywhere on your device.`});
        this.meta.updateTag({ name: 'keywords', content: `nexGTv News 24x7, Live News Streaming, Breaking News, Current Affairs, Today Top Stories` });
      break;

      case 'devotional':
        this.pageTitleService.setTitle(`nexGTv Devotional - Stream Spiritual Content Online`);
        this.meta.updateTag({ name: 'description', content: `Experience peace with nexGTv Devotional. Stream live aartis, bhajans, and spiritual discourses to stay connected with your faith anytime, anywhere.`});
        this.meta.updateTag({ name: 'keywords', content: `nexGTv Devotional, Stream Spiritual Content, Live Aartis, Bhajans, Spiritual Discourses` });
      break;

      case 'subscribe':
        this.pageTitleService.setTitle(`Subscribe for Devotional Content on NexGTv - Spiritual Videos`);
        this.meta.updateTag({ name: 'description', content: `Subscribe to NexGTv for exclusive devotional content, including spiritual videos, prayers, and bhajans. Join now for a fulfilling spiritual experience!`});
        this.meta.updateTag({ name: 'keywords', content: `Subscribe for Devotional Content, NexGTv, Spiritual Videos` });
      break;

      case 'aboutus':
        this.pageTitleService.setTitle(`About Us - Learn More About NexGTv's Services`);
        this.meta.updateTag({ name: 'description', content: `Discover NexGTv's mission, vision, and the services we offer. Learn how we bring quality entertainment and spiritual content to our users.`});
        this.meta.updateTag({ name: 'keywords', content: `About Us, NexGTv` });
      break;

      case 'contactus':
        this.pageTitleService.setTitle(`Contact Us - Get in Touch with NexGTv for Support & Inquiries`);
        this.meta.updateTag({ name: 'description', content: `Have questions or need support? Contact NexGTv today for assistance. We’re here to help with your queries and service requests.`});
        this.meta.updateTag({ name: 'keywords', content: `Contact Us, NexGTv, Support, Inquiries` });
      break;

      case 'faq':
        this.pageTitleService.setTitle(`FAQ - Frequently Asked Questions About NexGTv Services`);
        this.meta.updateTag({ name: 'description', content: `Find answers to common questions about NexGTv's services, subscriptions, and features. Get all the information you need in our FAQ section.`});
        this.meta.updateTag({ name: 'keywords', content: `FAQ, Frequently Asked Questions, NexGTv, Services` });
      break;

      case 'terms':
        this.pageTitleService.setTitle(`Terms and Conditions - NexGTv Service Agreement`);
        this.meta.updateTag({ name: 'description', content: `Review the terms and conditions for using NexGTv's services. Understand the rules and guidelines for a seamless and secure experience.`});
        this.meta.updateTag({ name: 'keywords', content: `Terms and Conditions, NexGTv, Service Agreement, Rules and Guidelines` });
      break;

      case 'policy':
        this.pageTitleService.setTitle(`Privacy Policy - NexGTv Data Protection and Privacy Practices`);
        this.meta.updateTag({ name: 'description', content: `Learn about NexGTv's privacy policy, data protection measures, and how we safeguard your personal information while using our services.`});
        this.meta.updateTag({ name: 'keywords', content: `Privacy Policy, NexGTv, Data Protection` });
      break;

      default:
        this.pageTitleService.setTitle(`nexGTv - Live TV, Movies, and On-Demand Entertainment`);
        this.meta.updateTag({ name: 'description', content: `Discover nexGTv, your go-to platform for live TV, movies, and on-demand shows. Stream 100+ channels, enjoy a variety of genres, and stay entertained anywhere.`});
        this.meta.updateTag({ name: 'keywords', content: `nexGTv, Live TV, On-Demand Entertainment` });
      break;
    }
  }

}

import { Component, OnInit, ViewChild, ElementRef, Input, HostListener,Renderer2  } from '@angular/core';

import * as algoliasearch from 'algoliasearch/lite';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { customOptionsForFourMiniSlider, customOptionsForBigSlider, justAdded, continueWatching, fourBigSlider } from "../owl-slider-config";
import { CatlougeTypeConstants } from '../../core/constants';
import { SearchService } from './search.service';
import { PageTitleService } from 'src/app/services/title/page-title.service';
import { CommonService } from "src/app/core/services/common.service";
import { UserService } from 'src/app/core';
import { PlayListService } from '../../views/playlist/playlist/playlist.service';
import { AssetsService } from 'src/app/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { environment } from '../../../environments/environment';
import { NavigationService } from 'src/app/core';
import { VideoPlayerService } from 'src/app/core/services/videoPlayerService';
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { Location } from '@angular/common';
declare var videojs: any;

declare var $: any;
// const searchClient = algoliasearch(
//   'C2JLM0V1F2',
//   '2732f3d5e7c3eef0ef0b8e0e497a15ca'
// );

if (localStorage && JSON.parse(localStorage.getItem('filterTypeData')) && JSON.parse(localStorage.getItem('filterTypeData')).search_engine) {
  if (JSON.parse(localStorage.getItem('filterTypeData')).search_engine == 'algolia') {
    var searchClient: any = algoliasearch(
      // 'C2JLM0V1F2',
      JSON.parse(localStorage.getItem('filterTypeData')).search_settings.app_id,
      // '2732f3d5e7c3eef0ef0b8e0e497a15ca'
      JSON.parse(localStorage.getItem('filterTypeData')).search_settings.api_key
    );
  } else {
    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
      server: {
        apiKey: JSON.parse(localStorage.getItem('filterTypeData')).search_settings != "" ? JSON.parse(localStorage.getItem('filterTypeData')).search_settings.key : "", //"KaOiHOYPSv7GR9Ir1ESqQTyrG9gajAhU", // Be sure to use an API key that only allows search operations
        nodes: [
          {
            host: JSON.parse(localStorage.getItem('filterTypeData')).search_settings.host, //"stage-search.digivive.com",
            // path: "", // Optional. Example: If you have your typesense mounted in localhost:8108/typesense, path should be equal to '/typesense'
            port: JSON.parse(localStorage.getItem('filterTypeData')).search_settings.port,
            protocol: JSON.parse(localStorage.getItem('filterTypeData')).search_settings.protocol, //"https",
          },
        ],
        cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
      },
      // The following parameters are directly passed to Typesense's search API endpoint.
      //  So you can pass any parameters supported by the search endpoint below.
      //  query_by is required.
      additionalSearchParameters: {
        query_by: "name,code,type,default_keyword,synopsis,genre_text",
        per_page: 20,
      },
    });
    var searchClient = typesenseInstantsearchAdapter.searchClient;
  }
}

interface SearchResult {
  hits: any[];  // Replace 'any' with the actual type of your hits if you have it
}

interface SearchData {
  results: SearchResult[];
}
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  providers:[UserService, PlayListService, CommonService]
})
export class SearchComponent implements OnInit {
  //livetv, movies,videos,tvshow
  hostname:any = 'NexGTv'
  searchSubscriber: any;
  owlOption: any;
  is_algolia: boolean = false;

  // globalConfig = {
  //   indexName: environment.production? "whitelabled" : "stage_whitelabled",
  //   searchClient
  // };

  globalConfig = {
    indexName: JSON.parse(localStorage.getItem('filterTypeData')).search_engine == 'algolia' ? JSON.parse(localStorage.getItem('filterTypeData')).search_settings.collection : JSON.parse(localStorage.getItem('filterTypeData')).search_settings.collection,
    searchClient
  };


  showAllRecordsTab:number =  1;
  allRecords: number = 0;

  showLiveTab:number =  0;
  liveTvRecords: number = 0;

  showMovieTab:number =  0;
  liveMoviesRecords: number = 0;

  showTvTab:number =  0;
  tvshowRecords: number = 0;

  showVideoTab:number =  0;
  videosRecords: number = 0;

  // assets_hover: boolean;
  // previewData: any;
  // playermodal: any = [];
  // checkPrview: any;
  // hoverAndExpend: any;
  // isPreviewVideoMuted: boolean = true;
  // nameOutsideOverlay: any;
  testNum: number[] = [1,1,1,1,1,1,1,1,1,1,1,1,1,1];
  activeTab: string = 'all-tab';


  

  //assets_hover:any;

  //allhits: any = { 'tvrecords': '', 'movierecord': '', 'tvshow': '', 'videorecord': '' };
  @ViewChild('totalAllRecordData') totalAllRecordData: ElementRef;
  @ViewChild('totalLiveTvRecordData') totalLiveTvRecordData: ElementRef;
  @ViewChild('totalMoviesRecordData') totalMoviesRecordData: ElementRef;
  @ViewChild('totalTvShowRecordData') totalTvShowRecordData: ElementRef;
  @ViewChild('totalVideosRecordData') totalVideosRecordData: ElementRef;


  @ViewChild('totalAllRecord') totalAllRecord: ElementRef;
  @ViewChild('totalLiveTvRecord') totalLiveTvRecord: ElementRef;
  @ViewChild('totalMoviesRecord') totalMoviesRecord: ElementRef;
  @ViewChild('totalVideosRecord') totalVideosRecord: ElementRef;
  @ViewChild('totalTvShowRecord') totalTvShowRecord: ElementRef;


  // @ViewChild('livetvTabActiveClick') livetvTabActiveClick: ElementRef;
  // @ViewChild('liveMoviebActiveClick') liveMoviebActiveClick: ElementRef;
  // @ViewChild('livetvshowTabActiveClick') livetvshowTabActiveClick: ElementRef;
  // @ViewChild('liveVideoTabActiveClick') liveVideoTabActiveClick: ElementRef;

  @ViewChild('totalSuggestions') totalSuggestions: ElementRef;
  suggestionsCount: number = 0;

  searchQuery: string = "";//,hitsPerPage:10
  catlouge = CatlougeTypeConstants.catlogue;
  isParentalControlEnabled;
  searchTimeout: any = undefined;

  userInfo:any;
  username: string;
  playListId:string;
  @ViewChild('addToWatchListSearchSuccess') addToWatchListSuccess;
  @ViewChild('itemAddedIntoWatch') itemAddedIntoWach: ElementRef;
  error_code:string = '';
  playListAvailable: boolean;
  playlist: any;
  tempAsset_id: any;
  isCloudFrontType: boolean = true;
  notFoundImg:any;
  fileterDataType: any;
  allSearchResults: any[];
  filteredSearchResults: any[] = [];//{ title: string, slug: string, results: any[] }[] = [];
  isAdult: string = 'no';
  isSearchResultFetched: boolean;
  watchlist: any[] = [];
  trendingResult: any[];
  recentSearches: any[];
  language: any;

  sticky: boolean = false;
  elementPosition: any;
  currentDeleteItem: string;
  private deleteRecentItemModalRef: NgbModalRef | null = null;
  private deleteAllRecentModalRef: NgbModalRef | null = null;
  freeIcon: string = environment.freeIcon;
  paidIcon: string = environment.paidIcon;
  queryParamSub: any;


  config1 = {
    indexName: JSON.parse(localStorage.getItem('filterTypeData')).search_engine != undefined && JSON.parse(localStorage.getItem('filterTypeData')).search_settings.collection,
    searchClient,
  };
  private offset  = 150;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private searchSerarvice: SearchService,
    private pageTitleService: PageTitleService,
    public commonService: CommonService,
    public UserService:UserService,
    public playListService:PlayListService,
    private modalService:NgbModal,
    private googleEvent: EventTrackingService,
    private navigationService: NavigationService,
    private location: Location,
    private assetsService: AssetsService,
    private el: ElementRef, private renderer: Renderer2
  ) { 
    this.fileterDataType = localStorage.getItem('filterTypeData');
  }

  ngOnInit(): void {
    this.language = this.commonService.languageChange();
    if (JSON.parse(localStorage.getItem('filterTypeData')).search_engine != undefined && JSON.parse(localStorage.getItem('filterTypeData')).search_engine == 'algolia') {
      this.is_algolia = true
    }
    this.notFoundImg ="landscape";
    if(this.commonService.filterType ==1){
      this.isCloudFrontType = true ;
    }else{
      this.isCloudFrontType = false;
    }

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if(this.userInfo){
      this.playListId = localStorage.getItem('playListId');
      this.username = this.userInfo.result.user.username;
    }
    // this.pageTitleService.setTitle(`Search`);
    // let rec = ['test', 'test l', 'test s', 'test live 8', 'test live 6', 'test live 5'];
    // localStorage.setItem('recentSearches', JSON.stringify(rec));

    this.recentSearches = JSON.parse(localStorage.getItem('recentSearches'));
    if (this.recentSearches === undefined || this.recentSearches === null) {
      this.recentSearches = []
    }
    if(localStorage.getItem('isAuthenticated')) {
      this.getWatchList();
      this.getTrending();
    }
    this.owlOption = customOptionsForFourMiniSlider;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {search: true},
      queryParamsHandling: 'merge',
    });
    setTimeout(() => {
      this.queryParamSub = this.route.queryParams.subscribe(params => {
        if (!params['search'])
          this.closeModal();
      });
    }, 0);
    // this.searchSubscriber = this.route.queryParams.subscribe(params => {
    //   this.searchQuery = '';
    //   this.searchQuery = params['q'];
    //   this.totalAllRecords();
    //   this.totalLiveTvRecords();
    //   this.totalMoviesRecords();
    //   this.totalVideosRecords();
    //   this.totalTvShowRecords();

    // });
  }


  ngAfterViewInit(){
    console.log('search ngafterviewinit')
  }

  ngOnDestroy() {
    this.queryParamSub?.unsubscribe();
    // this.route.queryParams.subscribe(queryParams => {
    //   console.log('search destroyed',queryParams);
    //   this.router.navigate([], {
    //     relativeTo: this.route,
    //     queryParams: queryParams,
    //   });
    // });
    // this.searchSubscriber.unsubscribe();
   
  }

  closeModal(resetParams?: boolean) {
    if (resetParams) {
      const queryParams = { ...this.route.snapshot.queryParams };
      delete queryParams['search'];
  
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: queryParams
      });
    }
    this.modalService.dismissAll();
    // try {
    //   document.querySelector("ngb-modal-window").classList.remove("show");
    //   document.querySelector("ngb-modal-backdrop").classList.remove("show");
    //   document.getElementById('nexgTV-app').click();
    //   document.querySelectorAll(".modal-backdrop").forEach(el => el.remove())
    //   document.querySelectorAll(".d-block").forEach(el => el.remove())
    //   document.body.style.overflow = "scroll";
    // } catch (e) {

    // } 
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

      // scroll position fixed search nav
    // if (scrollPosition > this.offset) {
    //   this.renderer.addClass(this.el.nativeElement.querySelector('.search-nav'), 'scrolled');
    // } else {
    //   this.renderer.removeClass(this.el.nativeElement.querySelector('.search-nav'), 'scrolled');
    // }
  }

  getSearchResults() {
    let params = {
      'x-typesense-api-key': JSON.parse(localStorage.getItem('filterTypeData')).search_settings.key,
    }
    let urlEncodedParams = this.UserService.convertoJSONToUrlEncoded(params);
    let body = {
      "searches": [
          {
              query_by: "name,code,type,default_keyword,synopsis,genre_text",
              per_page: 50,
              highlight_full_fields: "name,code,type,default_keyword,synopsis,genre_text",
              collection: "assets_nexgtv",
              q: this.searchQuery,
              filter_by: `is_adult:${this.isAdult}`, //"type: livetv catlogue:565c8c878c86ba151d29b689 AND ",
              page: 1
          }
      ]
    }
    this.searchSerarvice.searchResults(urlEncodedParams, body).subscribe((searchData: SearchData) => {
      this.allSearchResults = searchData.results[0].hits.filter(item => {
        return item.document.type === 'livetv'
      });
      this.isSearchResultFetched = true;
      // FILTERED SEARCH RESULTS WILL BE IMPLEMENTED WHEN ASSET TYPES OTHER THAN LIVETV ARE REQUIRED
      // this.filteredSearchResults = this.categorizeSearchResults(this.allSearchResults);
      this.calculateTotalSuggestions();

      // console.log(this.filteredSearchResults);
    }, (error) => {
      console.error('error fetching search data: ', error);
    });
  }
  
  categorizeSearchResults(allSearchResults) {
    let categories = {};

    allSearchResults.forEach(item => {
      const { type } = item.document;
      if (!categories[type]) {
        let name;
        switch (type) {
          case "livetv":
          name = "Live TV";
          break;
        case "tvshow":
          name = "TV Show";
          break;
        default:
          name = type.charAt(0).toUpperCase() + type.slice(1);
          break;
      }
        categories[type] = { name: name, type: type, results: [] };
      }
      categories[type].results.push(item);
      
    });

    return Object.values(categories);
  }
  

  onSearchInput(event: any) {
    // this.searchQuery = event.target.value;
    if(this.searchQuery?.length > 1) {
      setTimeout(() => {
        this.getSearchResults();
      }, 50);
      // this.calculateTotalSuggestions();
      this.pageTitleService.setTitle(`Search - ${this.searchQuery}`);
      // this.elementPosition = this.menuElement.nativeElement.offsetTop;
      // console.log('elementPosition: ', this.elementPosition);
    } else {
      this.pageTitleService.setTitle(`Explore Devotional Content on NexGTv - Spiritual Videos & More`);
    }
  }

  searchWithField(e: any) {
    this.recentSearches.shift();
    let term = e;
    term = term.trim();
    if (term.length === 0 || !term.trim()) {
      return false;
    }
    this.addToRecentSearches(term);
    this.searchQuery = term;
    this.getSearchResults();
    
  }

  addToRecentSearches(search: string) {
    this.googleEvent.eventEmitter("screen_view" , "screen_view" , this.username+'-'+'search',0,this.username , this.username+'-'+'search');
    let index = this.recentSearches.indexOf(search);
    if(index !== -1) {
      this.recentSearches.splice(index, 1);
    }
    this.recentSearches.unshift(search);
    // To limit array size
    if (this.recentSearches.length > 30) {
      this.recentSearches.pop();
    }
    localStorage.setItem('recentSearches', JSON.stringify(this.recentSearches));
  } 

  openDeleteRecentItemModal(modal, event: Event, search: string) {
    event.stopPropagation();
    this.deleteRecentItemModalRef = this.modalService.open(modal, {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: true,
      windowClass: 'auth popup_background',
    });
    this.deleteRecentItemModalRef.result.then((result) => {
      // Handle result
    }, (reason) => {
      // Handle dismiss
    });
    this.currentDeleteItem = search;
  }

  closeDeleteRecentItemModal() {
    if (this.deleteRecentItemModalRef) {
      this.deleteRecentItemModalRef.close();
      this.deleteRecentItemModalRef = null;
    }
  }

  openDeleteAllRecentModal(modal, event: Event) {
    event.stopPropagation();
    this.deleteAllRecentModalRef = this.modalService.open(modal, {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: true,
      windowClass: 'auth popup_background',
    });
    this.deleteAllRecentModalRef.result.then((result) => {
      // Handle result
    }, (reason) => {
      // Handle dismiss
    });
  }

  closeDeleteAllRecentModal() {
    if (this.deleteAllRecentModalRef) {
      this.deleteAllRecentModalRef.close();
      this.deleteAllRecentModalRef = null;
    }
  }

  removeFromRecentSearches(event: Event, search: string) {
    event.stopPropagation();
    let index: number = this.recentSearches.findIndex(item => search === item);
    if (index !== -1) {
      this.recentSearches.splice(index, 1);
      localStorage.setItem('recentSearches', JSON.stringify(this.recentSearches));
    }
    this.closeDeleteRecentItemModal();

  }

  clearAllRecentSearches() {
    this.recentSearches = [];
    localStorage.setItem('recentSearches', JSON.stringify(this.recentSearches));
    this.closeDeleteAllRecentModal();
  }

  onInputBlur() {
    console.log('onInputBlur');
    if(this.searchQuery && this.searchQuery.length >=2) {
      this.addToRecentSearches(this.searchQuery);
    }
  }

  get allsearchParameters1() {
    //alert("yes");
    return {
      query: this.searchQuery, 'filters': 'type:livetv'
    }
  }

  get allsearchParameters() {
    
    this.isParentalControlEnabled = this.searchSerarvice.parentalControlStatus();

    if (JSON.parse(localStorage.getItem('filterTypeData')).search_engine != undefined && JSON.parse(localStorage.getItem('filterTypeData')).search_engine == 'algolia') {
      if (this.isParentalControlEnabled == null || this.isParentalControlEnabled == '0') {
        return {
          query: this.searchQuery, 'filters': 'catlogue:' + this.catlouge
        }
      }

      return {
        query: this.searchQuery, 'filters': 'catlogue:' + this.catlouge + ' AND ' + 'is_adult:no'
      }
    } else {
      if (this.isParentalControlEnabled == null || this.isParentalControlEnabled == '0') {
        return {
          query: this.searchQuery
        }
      }

      return {
        query: this.searchQuery, 'filters': 'is_adult:no'
      }
    }
  }
  get moviesearchParameters() {

    this.isParentalControlEnabled = this.searchSerarvice.parentalControlStatus();

    if (this.isParentalControlEnabled == null || this.isParentalControlEnabled == '0') {
      return {
        query: this.searchQuery, 'filters': 'type:movie' //  'catlogue:' + this.catlouge+ ' AND ' +
      }
    }

    return {
      query: this.searchQuery, 'filters': 'type:movie' //  'catlogue:' + this.catlouge + ' AND ' + 'is_adult:no'+ ' AND ' +
    }
  }
  get tvshowsearchParameters() {

    this.isParentalControlEnabled = this.searchSerarvice.parentalControlStatus();

    if (this.isParentalControlEnabled == null || this.isParentalControlEnabled == '0') {
      return {
        query: this.searchQuery, 'filters': 'catlogue:' + this.catlouge+ ' AND ' + 'type:tvshow'
      }
    }

    return {
      query: this.searchQuery, 'filters': 'catlogue:' + this.catlouge + ' AND ' + 'is_adult:no'+ ' AND ' + 'type:tvshow'
    }
  }
  get videosearchParameters() {

    this.isParentalControlEnabled = this.searchSerarvice.parentalControlStatus();

    if (this.isParentalControlEnabled == null || this.isParentalControlEnabled == '0') {
      return {
        query: this.searchQuery, 'filters': 'catlogue:' + this.catlouge+ ' AND ' + 'type:video'
      }
    }

    return {
      query: this.searchQuery, 'filters': 'catlogue:' + this.catlouge + ' AND ' + 'is_adult:no'+ ' AND ' + 'type:video'
    }
  }
  get livetvsearchParameters() {

    this.isParentalControlEnabled = this.searchSerarvice.parentalControlStatus();

    if (this.isParentalControlEnabled == null || this.isParentalControlEnabled == '0') {
      return {
        query: this.searchQuery, 'filters': 'type:livetv' // 'catlogue:' + this.catlouge+ ' AND ' +
      }
    }

    return {
      query: this.searchQuery, 'filters': 'type:livetv' // 'catlogue:' + this.catlouge + ' AND ' + 'is_adult:no'+ ' AND ' + 
    }
  }

  calculateTotalSuggestions() {
    setTimeout(() => {
      if (this.totalSuggestions.nativeElement.value) {
        this.suggestionsCount = this.totalSuggestions.nativeElement.value;
        console.log('if blcok of calculateTotalSuggestions');
      }
    }, 100);
    console.log('suggestionsCount: ', this.suggestionsCount);
  }

  totalLiveTvRecords() {
    setTimeout(() => {
      if (this.totalLiveTvRecord == undefined) {
        this.liveTvRecords = 0;
        return;
      }
      if (this.totalLiveTvRecord.nativeElement.value != 'undefined')
        this.liveTvRecords = this.totalLiveTvRecord.nativeElement.value;
    }, 1000);
  }

  totalMoviesRecords() {
    setTimeout(() => {
      if (this.totalMoviesRecord == undefined) {
        this.liveMoviesRecords = 0;
        return;
      }
      if (this.totalMoviesRecord.nativeElement.value != 'undefined')
        this.liveMoviesRecords = this.totalMoviesRecord.nativeElement.value;

    }, 1000);
  }

  totalTvShowRecords() {
    setTimeout(() => {
      if (this.totalTvShowRecord == undefined) {
        this.tvshowRecords = 0;
        return;
      }
      if (this.totalTvShowRecord.nativeElement.value != 'undefined') {
        this.tvshowRecords = this.totalTvShowRecord.nativeElement.value;

      } else {
        this.tvshowRecords = 0;
      }
    }, 1000);
  }

  totalAllRecords() {
    console.log('search Running');
    setTimeout(() => {
      if (this.totalAllRecord == undefined) {
        this.allRecords = 0;
        return;
      }
      if (this.totalAllRecord.nativeElement.value != 'undefined')
        this.allRecords = this.totalAllRecord.nativeElement.value;
        this.check_into_watchlist(this.allRecords);
        console.log('allRecords: ', this.allRecords);
        console.log('allRecordsData: ', JSON.parse(this.totalAllRecordData.nativeElement.value));

      }, 1000);
  }
  totalVideosRecords() {
    setTimeout(() => {
      if (this.totalVideosRecord == undefined) {
        this.videosRecords = 0;
        return;
      }
      if (this.totalVideosRecord.nativeElement.value != 'undefined')
        this.videosRecords = this.totalVideosRecord.nativeElement.value;

    }, 1000);
  }

  jsonString(data) {
    return JSON.stringify(data);
  }

  // changeTab(tab) {
  //   switch (tab) {
  //     case 'livetv':
  //       this.livetvTabActiveClick.nativeElement.click();
  //       break;
  //     case 'movies':
  //       this.liveMoviebActiveClick.nativeElement.click();
  //       break;
  //     case 'tvshows':
  //       this.livetvshowTabActiveClick.nativeElement.click();
  //       break;
  //     case 'videos':
  //       this.liveVideoTabActiveClick.nativeElement.click();
  //       break;

  //   }
  // }

  goto(asset: any, content) {

    console.log('asset: ', asset);

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    

    if(this.userInfo == undefined || Object.keys(this.userInfo).length == 0){
      // this.commonService.setBeforeLoginState(tempQueryStr);
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      }, (reason) => {
      });

      return false;
    }


    // let data = {
    //   "code": tempQueryStr.code,
    //   "catlogue": tempQueryStr.catlogue[0],
    //   "plateform": "web",
    //   "name": tempQueryStr.name,
    //   "charge_code": tempQueryStr.charge_code ? tempQueryStr.charge_code : '',
    //   "genre": tempQueryStr.genre ? tempQueryStr.genre : '',
    //   "type": tempQueryStr.type ? tempQueryStr.type : ''
    // }
    // data.type = data.type =='live'?'livetv':data.type;
    this.commonService.goToDetail(asset, 'Screen_Search');
    // this.router.navigate(['detail-episode'], { queryParams: data })
  }

  strToLower(str) {
    if (str)
      return str.toLowerCase();
    else
      return 'na';
  }

  AddToWatchList(e, item, content){
    e.stopPropagation();
    if(Object.keys(this.userInfo.result).length > 0) {

      let userUnique = '';
      if (this.userInfo.result.register_by == "mobile") {
        userUnique = this.userInfo.result.mobile;
      } else {
        userUnique = this.userInfo.result.emailaddress;
      }

      this.googleEvent.eventEmitter("Watchlist" , "Watchlist" , userUnique+'-'+item.code+'-'+item.name,0,userUnique , userUnique+'-'+item.code+'-'+item.name);

      let addToplayListParams = {
        playlistid:this.playListId?this.playListId:'',
        catlogue:CatlougeTypeConstants.catlogue,
        plateform:"web",
        playingtype:"Video",
        assetId:item.asset_mongo_id?item.asset_mongo_id:item._id,
        assetType:item.type?item.type:'',
        action:"saveAssetInPlaylist",
      }
  
      console.log(addToplayListParams);
      let urlEncodedToplayListParams = this.UserService.convertoJSONToUrlEncoded(addToplayListParams);    
      console.log(item.asset_mongo_id?item.asset_mongo_id:item._id);
      if((item.asset_mongo_id?item.asset_mongo_id:item._id) != undefined){
            this.playListService.addToPlayList(urlEncodedToplayListParams).subscribe((data)=>{
              this.error_code = data.error_code;
                console.log(this.error_code);
                if(data.error_code == '200'){
                  // this.itemAddedIntoWach.nativeElement.innerHTML = `Item Successfully added ${this.setPlayListItem?.name} into your Watchlist.`;  
                  document.getElementById('itemAddSuccessMsg').innerHTML= `Successfully added to Watchlist.`; 
                  this.addToWatchListSuccess.nativeElement.click(); 

                }
                if(data.error_code == '209'){
                  // this.itemAddedIntoWach.nativeElement.innerHTML = 'This item already exist in your Watchlist ';  
                  document.getElementById('itemAddSuccessMsg').innerHTML=`Remove from Watchlist.`;  
                  this.addToWatchListSuccess.nativeElement.click();
                  this.deleteFromWatchList(item)
                }
                
                setTimeout(()=>{
                  document.getElementById('closeAddToWatchListFromSearch').click();
                }, 1500);
    

              console.log(this.error_code)
            // this.toastr.success('Success!', '', { timeOut: 2000 });
              // this.closeAddtoWatchListModal.nativeElement.click();
  
          });
      } else {
        // this.closeAddtoWatchListModal.nativeElement.click();
      }
    } else {
      // console.log(content);
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      }, (reason) => {
      });
    }
  }


  getWatchList(){
    this.playListService.getPlaylist().subscribe(playlistData => {
      this.watchlist = playlistData.result;
    });
  }

  getTrending() {
    this.assetsService.getTrending().subscribe(trendingData => {
      this.trendingResult = trendingData.result;
    });
  }


  check_into_watchlist(item){
    console.log(item);
  }

  addToWatchlistArray(item) {
    this.watchlist.push(item);
    if(this.searchQuery.length > 1 && this.allSearchResults?.length > 0) {
      this.updateTrendingSearchResults(item, 1);
    }
  }

  removeFromWatchlistArray(item) {
    let index = this.watchlist.findIndex(ele => ele.code === item.code);
    this.watchlist.splice(index, 1);
    if(this.searchQuery.length > 1 && this.allSearchResults?.length > 0) {
      this.updateTrendingSearchResults(item, 0);
    }
  }
  
  deleteFromWatchList(item){
    
  }
  
  handleBack() {
    this.navigationService.back();
  }

  assetHover(asset) {
    const index = this.watchlist.findIndex(item => item.code === asset.code);
    if (index !== -1 && (!asset?.is_watchlist_added || asset?.is_watchlist_added == 0)) {
      this.updateAllSearchResults(asset, 1);
      this.updateFilteredSearchResults(asset, 1);
    } else if (index === -1 && asset?.is_watchlist_added == 1) {
      this.updateAllSearchResults(asset, 0);
      this.updateFilteredSearchResults(asset, 0);
    }
  }

  updateTrendingSearchResults(asset, status: number) {
    const updatedTrendingResults = this.trendingResult.map(item => {
      if(item.code === asset.code) {
        return {
          ...item,
          is_watchlist_added: status
        };
      }
      return item;
    });

    this.trendingResult = updatedTrendingResults;
  }

  updateAllSearchResults(asset, status: number) {
    // Create a deep copy of allSearchResults to trigger Angular change detection
    const updatedSearchResults = this.allSearchResults.map(resultItem => {
      if (resultItem.document.code === asset.code) {
        return {
          ...resultItem,
          document: {
            ...resultItem.document,
            is_watchlist_added: status
          }
        };
      }
      return resultItem;
    });
    // Assign the updated array back to allSearchResults
    this.allSearchResults = updatedSearchResults;
  }

  
  updateFilteredSearchResults(asset, status: number) {
    let ele = this.filteredSearchResults.findIndex(item => {
      return item.type === asset.type;
    });
  
    if (ele !== -1) {
      const newResultsArray = this.filteredSearchResults[ele].results.map(resultItem => {
        if (resultItem.document.code === asset.code) {
          return {
            ...resultItem,
            document: {
              ...resultItem.document,
              is_watchlist_added: status
            }
          };
        }
        return resultItem;
      });
  
      let newFilteredSearchResults = [...this.filteredSearchResults];
      newFilteredSearchResults[ele] = {
        ...newFilteredSearchResults[ele],
        results: newResultsArray
      }
  
      this.filteredSearchResults = newFilteredSearchResults;
    }
  }
  
  

  updateActiveTab(event: Event) {
    const target = event.target as HTMLElement;
    const targetTab = target.getAttribute('data-target')?.substring(1); // Remove the leading '#'
    if (targetTab) {
      this.activeTab = targetTab;
    }
  }
  

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
}